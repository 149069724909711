import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import useSiteMetadata from '../hooks/use-site-metadata'
import Layout from '../components/Layout'
import PostList from '../components/PostList'

const Author = ({ data, uri }) => {
  const { posts, name } = data.allWpUser.edges[0].node
  const totalCount = (posts && posts.nodes.length) || 0
  const { title: siteTitle } = data.site.siteMetadata
  const title = `${totalCount} post${totalCount === 1 ? '' : 's'} by ${name}`
  // The `authored_wordpress__POST` returns a simple array instead of an array
  // of edges / nodes. We therefore need to convert the array here.
  if (data.allWpUser.edges !== null) {
    const post = posts.nodes.map((post, index) => {
      return post
    })

    const { url: siteUrl } = useSiteMetadata()

    return (
      <Layout>
        <Helmet title={`${name} | ${siteTitle}`}>
          <link rel="canonical" href={`${siteUrl}${uri}`} />
        </Helmet>
        <PostList filteredPosts={post} title={title} type="author" />
      </Layout>
    )
  } else {
    return (
      <Layout>
        <Helmet title={`${name} | ${siteTitle}`} />
        <div className="center-author">
          <button>
            <Link to={'/'}>No Posts, Back to HomePage</Link>
          </button>
        </div>
      </Layout>
    )
  }
}

export default Author

export const pageQuery = graphql`
  query AuthorPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWpUser(filter: { id: { eq: $id } }) {
      edges {
        node {
          name
          posts {
            nodes {
              id
            }
          }
        }
      }
    }
  }
`
